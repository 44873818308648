<template>
  <v-app>
    <v-app-bar app color="#FFFFFF" height="80">
      <v-row justify="center">
        <!-- <div class="d-flex align-center"> -->
          <v-img
            alt="Villa Logo"
            class="shrink"
            contain
            min-width="150"
            :src="require('./assets/logo.png')"
            transition="scale-transition"
            width="200"
          />

          <!-- <v-img
            alt="Vuetify Name"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="100"
            src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
            width="100"
          /> -->
        <!-- </div> -->
      </v-row>

      <!-- <v-spacer></v-spacer> -->

      <!-- <span color="primary" class="title mr-2">Privacy Policy</span> -->

      <!-- <v-spacer></v-spacer> -->

      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <GoTop />
  </v-app>
</template>

<script>
import GoTop from "@/components/GoTop";

export default {
  name: "App",
  components: {
    GoTop,
  },
  data: () => ({
    //
  }),
};
</script>

<style scoped>
title {
  /* font-size: 10px; */
  background-color: #ffffff;
  /* color: #3F51B5; */
}
</style>
