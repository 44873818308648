<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- <h3 class="text-center font-weight-bold mb-0">นโยบายการคุ้มครองข้อมูลส่วนบุคคล</h3>
        <h3 class="text-center font-weight-bold mb-5">(Personal Data Protection Policy)</h3> -->

        <p class="subheading font-weight-regular" v-if="typeObj.type == '1'">
          <!-- {{ typeObj.message }} -->
            วิลล่ามาร์เก็ตขอขอบพระคุณท่าน ที่ร่วมเป็นสมาชิกและใช้บริการเรามาตลอด เราขอมอบส่วนลด 100 บาท ให้กลับท่านสมาชิกเพียงแจ้ง CODE: MEMBER1 และเบอร์โทรสมาชิกที่แคชเชียร์ เมื่อสั่งซื้อสินค้าภายในวันที่ 30 มิถุนายน 2565 นี้  และเพื่อให้บริการท่านสะดวก ถูกต้องตามกฏหมายและมีคุณภาพ เราขออนุญาติส่งข่าวสารและสิทธิประโยชน์ให้กับท่านเช่นเดิม หากท่านไม่ต้องการรับการบริการหรือข้อมูลข่าวสารจากเรา สามารถกดที่ปุ่ม "ยกเลิกรับข่าวสารและสิทธิประโยชน์"
        </p>

        <p class="subheading font-weight-regular" v-else-if="typeObj.type == '2'">
          Dear Customer,<br/><br/>
          As The Personal Data Protection Act B.E. 2562 will come into full effect on 1 June 2022, Villa Market JP Co., Ltd understands the importance and will fully comply with the Act. Villa Market has provided the right knowledge regarding data protection to all our employees.<br/><br/>
          For more information about the privacy policy. <a href="https://shop.villamarket.com/privacy-policy" target="_blank">Please click here</a><br/>
          <!-- If you prefer not to receive these messages in the future, <a href="https://shop.villamarket.com/privacy-policy" target="_blank">please click here</a> to unsubscribe. -->
        </p>

        <p class="subheading font-weight-regular" v-else>
          {{ typeObj.message }}
        </p>

        <v-divider class="mb-3"></v-divider>

        <br />
        <v-row justify="center" class="font-weight-bold">
          <!-- <v-btn width="200" disabled depressed v-if="!isShow">UNSUBSCRIBE</v-btn> -->
          <v-btn color="#B71C1C" dark @click="dialog = true" v-if="isShow" width="300">UNSUBSCRIBE ({{ channelObj.desc }})</v-btn>
        </v-row><br /><br /><br />
      </v-col>
    </v-row>

    <v-overlay :value="isOverlay">
      <v-progress-circular
        indeterminate
        color="primary"
        width="6"
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-snackbar
      v-model="isAccept"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="isAccept = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <template>
        <div class="text-center">
            <v-dialog
            persistent
            v-model="dialog"
            width="500"
            >
            <v-card>
                <v-card-title class="text-h5 primary" color="#ffffff">
                UNSUBSCRIBE ({{ channelObj.desc }})
                </v-card-title>

                <v-card-text class="pa-5 text-center font-weight-bold">
                    You no longer miss us?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog = false" width="100">Back</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click="unsubscribeClick" width="100">Confirm</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>
    </template>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Unsubscribe",
  data() {
    return {
      dialog: false,
      isShow: true,
      isAccept: false,
      isOverlay: false,
      text: "Success",
      timeout: 2000,

      types: [
        {
          type: "1",
          message: "111"
        },
        {
          type: "2",
          message: "222"
        },
      ],
      typeObj: {},

      channels: [ { channel: "1", desc: "SMS" }, { channel: "2", desc: "LINE" } ],
      channelObj: {},

      phoneNo: "",
      type: "",
      channel: "",
      brcode: ""
    }
  },
  mounted() {
    this.phoneNo = this.$route.params.id ?? "";
    this.type = this.$route.params.type ?? "";
    this.channel = this.$route.params.channel ?? "";
    this.brcode = this.$route.params.brcode ?? "";

    this.typeObj = this.types.find(d => d.type === this.type);
    if (!this.typeObj) {
      this.typeObj = { type: "", message: "type not found" }
    }
    // console.log(this.typeObj)

    this.channelObj = this.channels.find(d => d.channel === this.channel);
    if (!this.channelObj) {
      this.channelObj = { channel: "", desc: "" }
    }
    // console.log(this.channelObj)
  },
  methods: {
    unsubscribeClick() {
      try {
        this.dialog = false;
        // let phoneNo = this.$route.params.id ?? "";
        // let type = this.$route.params.type ?? "";
        // let channel = this.$route.params.channel ?? "";
        // let brcode = this.$route.params.brcode ?? "";
        this.isOverlay = true;
        let payload = {
            "action": "UNSUBSCRIBE_UPDATE",
            "data_t01": `${this.phoneNo}`,
            "data_t02": `${this.type}`,
            "data_t03": `${this.channel}`,
            "data_t04": `${this.brcode}`
        };
        const token = Buffer.from("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJBUElWSUxMQSIsImlhdCI6MTYwMzI2NTkzMzYyMn0.0dc6e71d4GFWgbEbWDUfVpHUc74G5Sxy6B1haBtCiLE")

        const instance = axios.create({
          baseURL: 'http://openapi.villamarketjp.com:7000/villa/api',
          headers: {'Authorization': `${token}`, 'Content-Type': `application/json`}
        });

        instance
          .post("/backend", payload)
          .then(response => {
            // console.log("-- response --: ", response.data.data)
            let result = response?.data?.data[0];
            // console.log("-- result --: ", result)
            if (result != null)
              this.text = result.msg
              if (result.err == "N" && this.channelObj?.channel=="2") {
                this.notifyLine()
              }
            else
              this.text = "Error"
          })
          .catch(error => {
            this.text = error.toString()
            // console.error(error)
            // alert(error)
          })
      } catch (error) {
        // console.error(error)
        this.text = error.toString()
        // alert(error)
      }
      finally {
        setTimeout(() => {
          this.isOverlay = false;
          this.isAccept = true;
        }, 1000);
      }
    },
    notifyLine() {
      // console.log('-- Notify Line --')
      try {
        let payload = JSON.stringify({
          "line_id" : `${this.phoneNo}`
        });
        // const token = Buffer.from("Basic Ym90aWVuY2xhZGZqaWFzZGZlOmFzY2lkc29lY2pkZHNib3Q=")

        const instance = axios.create({
          baseURL: 'https://pkg004.notifypkg.villajp.com/external/party_action/pdpa',
          // headers: {'Authorization': `${token}`}
          headers: {'Content-Type': `application/json`}
        });

        instance
          .post("/unsubscribe", payload, {  auth: { username: "botiencladfjiasdfe", password: "ascidsoecjddsbot"} })
          .then(response => {
            console.log("response: ", response.data?.status)
            // let result = response?.data?.data[0];
            // // console.log("-- result --: ", result)
            // if (result != null)
            //   this.text = result.msg
            //   if (result.err == "N") {
            //     this.notifyLine()
            //   }
            // else
            //   this.text = "Error"
          })
          .catch(error => {
            this.text = error.toString()
            // console.error(error)
            // alert(error)
          })
      } catch (error) {
        // console.error(error)
        this.text = error.toString()
        // alert(error)
      }
      // finally {
      // }
    }
  },
};
</script>

<style scoped>
div {
  font-size: 15px;
}
.indent1 {
    text-indent: 30px;
}
.indent2 {
    text-indent: 60px;
}
.indent3 {
    text-indent: 90px;
}
.header {
  color: #3F51B5;
}
</style>