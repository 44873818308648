import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import Unsubscribe from "../views/Unsubscribe.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/privacy-policy/:id",
    name: "PrivacyPolicy2",
    component: PrivacyPolicy,
  },
  {
    path: "/integrate/:servicename/:arg1/:arg2/:arg3",
    name: "Integrate1",
    component: () => import("@/views/Integrate.vue"),
  },
  {
    path: "/integrate/:servicename/:arg1/:arg2/:arg3/:arg4/:arg5/:arg6",
    name: "Integrate2",
    component: () => import("@/views/Integrate.vue"),
  },
  // {
  //   path: "/unsubscribe",
  //   name: "Unsubscribe",
  //   component: Unsubscribe,
  // },
  // {
  //   path: "/unsubscribe/:id",
  //   name: "Unsubscribe2",
  //   component: Unsubscribe,
  // },
  {
    path: "/unsubscribe/:id/:type/:channel/:brcode",
    name: "Unsubscribe",
    component: Unsubscribe,
  },

  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "*",
    redirect: "/"
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
