<template fluid>
  <v-btn
    v-show="isShow"
    v-scroll="onScroll"
    color="primary"
    dark
    fixed
    bottom
    right
    fab
    @click="toTop"
  >
    <v-icon>keyboard_arrow_up</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "GoTop",
  data() {
    return {
      isShow: false,
    };
  },
  //   async mounted() {
  //   },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.isShow = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style></style>
